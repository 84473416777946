import React, { Component } from "react";
import "./liveprojects.css";
import DegreeCard from "../../components/webSites/WebSites.js";
import { websites } from "../../portfolio";
import { Fade } from "react-reveal";

class LiveProjects extends Component {
  render() {
    const theme = this.props.theme;
    return (
      <div className="main" id="educations">
        <div className="educations-header-div">
          <Fade bottom duration={2000} distance="20px">
            <h1 className="educations-header" style={{ color: theme.text }}>
              Websites and Mobile Applications
            </h1>
          </Fade>
        </div>
        <div className="educations-body-div">
          {websites.websites.map((websites) => {
            return <DegreeCard websites={websites} theme={theme} />;
          })}
        </div>
      </div>
    );
  }
}

export default LiveProjects;