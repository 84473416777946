/* Change this file to get your personal Porfolio */

// Website related settings
const settings = {
    isSplash: true, // Change this to false if you don't want Splash screen.
};

//SEO Related settings
const seo = {
  title: "Hannan Max",
  description:
    "Mobile application developer, the Experienced mobile app developer who has a track record of success creating apps that are both well-received and commercially viable.",
  og: {
    title: "Hannan Max",
    type: "website",
    url: "https://www.hannanmax.com/",
    site_name: "Hannan Max",
    image: "https://www.hannanmax.com/android-icon-192x192.png",
  },
};

//Home Page
const greeting = {
  title: "Hannan Max",
  logo_name: "Hannan Max",
  nickname: "Hannanahmad Shaikh",
  subTitle:
    "Mobile application developer, I use programming languages and source code to create software internally or on behalf of clients. Experienced mobile app developer who has a track record of success creating apps that are both well-received and commercially viable. Skilled with working as a team and incorporating input into projects. Ability to always look for ways to improve upon an already existing app to keep people downloading it and enjoying it. Strong eye for detail and tenacity to never quit on something until it is absolutely perfect.",
  resumeLink:
    "https://drive.google.com/file/d/1sxkJS0NfLSXxpeln-gwSRXxiGz9ml4c3/view?usp=sharing",
  portfolio_repository: "https://github.com/hannanmax",
  hire_me: "https://api.whatsapp.com/send?phone=12896001062&text=Hey%20There,%20I%20would%20like%20to%20hire%20you...",
};

const socialMediaLinks = [
  {
    name: "Github",
    link: "https://github.com/hannanmax",
    fontAwesomeIcon: "fa-github", // Reference https://fontawesome.com/icons/github?style=brands
    backgroundColor: "#181717", // Reference https://simpleicons.org/?q=github
  },
  {
    name: "Instagram",
    link: "https://www.instagram.com/hannan_max/",
    fontAwesomeIcon: "fa-instagram", // Reference https://fontawesome.com/icons/instagram?style=brands
    backgroundColor: "#E4405F", // Reference https://simpleicons.org/?q=instagram
  },
  {
    name: "LinkedIn",
    link: "https://www.linkedin.com/in/hannanmax/",
    fontAwesomeIcon: "fa-linkedin-in", // Reference https://fontawesome.com/icons/linkedin-in?style=brands
    backgroundColor: "#0077B5", // Reference https://simpleicons.org/?q=linkedin
  },
  {
    name: "YouTube",
    link: "https://www.youtube.com/channel/UCP6ATvQ5igEoX7JI6gQIENg",
    fontAwesomeIcon: "fa-youtube", // Reference https://fontawesome.com/icons/youtube?style=brands
    backgroundColor: "#FF0000", // Reference https://simpleicons.org/?q=youtube
  },
  {
    name: "GooglePlay",
    link: "https://play.google.com/store/apps/dev?id=7261248402572870851",
    fontAwesomeIcon: "fa-google-play", // Reference https://fontawesome.com/icons/linkedin-in?style=brands
    backgroundColor: "#000000", // Reference https://simpleicons.org/?q=linkedin
  },
  {
    name: "Gmail",
    link: "mailto:hannanmaxdev@gmail.com",
    fontAwesomeIcon: "fa-google", // Reference https://fontawesome.com/icons/google?style=brands
    backgroundColor: "#D14836", // Reference https://simpleicons.org/?q=gmail
  },
  {
    name: "Twitter",
    link: "https://twitter.com/hannanmax",
    fontAwesomeIcon: "fa-twitter", // Reference https://fontawesome.com/icons/twitter?style=brands
    backgroundColor: "#1DA1F2", // Reference https://simpleicons.org/?q=twitter
  },
  {
    name: "Facebook",
    link: "https://www.facebook.com/hannanmaxdeveloper/",
    fontAwesomeIcon: "fa-facebook-f", // Reference https://fontawesome.com/icons/facebook-f?style=brands
    backgroundColor: "#1877F2", // Reference https://simpleicons.org/?q=facebook
  },
];

const skills = {
  data: [
    {
      title: "Mobile Application Developer",
      fileName: "DataScienceImg",
      skills: [
        "⚡ Developing highly scalable application and ready models for various feilds",
        "⚡ Experience of working with Android Studio & XCode",
        "⚡ Complex quantitative modelling for dynamic forecasting and time series analysis",
        "⚡ Can work easily with Java, Kotlin, Flutter & Swift",
      ],
      softwareSkills: [
        {
          skillName: "Android Studio",
              fontAwesomeClassname: "mdi:android-studio",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "Java",
          fontAwesomeClassname: "logos:java",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "Kotlin",
          fontAwesomeClassname: "logos:kotlin",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "Swift",
          fontAwesomeClassname: "logos:swift",
          style: {
            backgroundColor: "transparent",
            color: "#3776AB",
          },
        },
          {
              skillName: "Flutter",
              fontAwesomeClassname: "vscode-icons:file-type-flutter",
              style: {
                  backgroundColor: "transparent",
                  color: "#3776AB",
              },
          },
      ],
    },
    {
      title: "Organizer - GDGSudbury (Google Developer Group Sudbury)",
      fileName: "GDGSudbury",
      skills: [
        "⚡ Passionate leader in community dedicated to helping others learn and connect.",
        "⚡ Plan and host meetup events on a wide range of technical topics typically on a monthly basis in a location nearby.",
      ],
      softwareSkills: [
        {
          skillName: "GOOGLE",
          fontAwesomeClassname: "simple-icons:google",
          style: {
            color: "#02569B",
          },
        },
      ],
    },
    {
      title: "UI/UX Design",
      fileName: "DesignImg",
      skills: [
        "⚡ Designing highly attractive user interface for mobile and web applications",
        "⚡ Customizing logo designs and building logos from scratch",
        "⚡ Creating the flow of application functionalities to optimize user experience",
      ],
      softwareSkills: [
        {
          skillName: "Adobe XD",
          fontAwesomeClassname: "simple-icons:adobexd",
          style: {
            color: "#FF2BC2",
          },
        },
        {
          skillName: "Figma",
          fontAwesomeClassname: "simple-icons:figma",
          style: {
            color: "#F24E1E",
          },
        },
        {
          skillName: "Adobe Illustrator",
          fontAwesomeClassname: "simple-icons:adobeillustrator",
          style: {
            color: "#FF7C00",
          },
        },
      ],
    },
    {
      title: "Cloud Infra-Architecture",
      fileName: "CloudInfraImg",
      skills: [
        "⚡ Experience working on multiple cloud platforms",
        "⚡ Hosting and maintaining websites on virtual machine instances along with integration of databases",
        "⚡ Deploying deep learning models on cloud to use on mobile devices",
        "⚡ Setting up streaming jobs from DB to Server or vice-versa on GCP and AWS",
      ],
      softwareSkills: [
        {
          skillName: "GCP",
          fontAwesomeClassname: "simple-icons:googlecloud",
          style: {
            color: "#4285F4",
          },
        },
        {
          skillName: "AWS",
          fontAwesomeClassname: "simple-icons:amazonaws",
          style: {
            color: "#FF9900",
          },
        },
        {
          skillName: "Azure",
          fontAwesomeClassname: "simple-icons:microsoftazure",
          style: {
            color: "#0089D6",
          },
        },
        {
          skillName: "Firebase",
          fontAwesomeClassname: "simple-icons:firebase",
          style: {
            color: "#FFCA28",
          },
        },
        {
          skillName: "PostgreSQL",
          fontAwesomeClassname: "simple-icons:postgresql",
          style: {
            color: "#336791",
          },
        },
        {
          skillName: "MongoDB",
          fontAwesomeClassname: "simple-icons:mongodb",
          style: {
            color: "#47A248",
          },
        },
        {
          skillName: "Docker",
          fontAwesomeClassname: "simple-icons:docker",
          style: {
            color: "#1488C6",
          },
        },
        {
          skillName: "Kubernetes",
          fontAwesomeClassname: "simple-icons:kubernetes",
          style: {
            color: "#326CE5",
          },
        },
      ],
    },
  ],
};

// Education Page
const competitiveSites = {
  competitiveSites: [
    {
      siteName: "HackerRank",
      iconifyClassname: "simple-icons:hackerrank",
      style: {
        color: "#2EC866",
      },
      profileLink: "https://www.hackerrank.com/16bcah06",
    },
    {
      siteName: "Codechef",
      iconifyClassname: "simple-icons:codechef",
      style: {
        color: "#5B4638",
      },
        profileLink: "https://www.codechef.com/users/hannan_max",
    },
    {
      siteName: "Codeforces",
      iconifyClassname: "simple-icons:codeforces",
      style: {
        color: "#1F8ACB",
      },
        profileLink: "http://codeforces.com/profile/hannan_max",
    },
    {
      siteName: "Hackerearth",
      iconifyClassname: "simple-icons:hackerearth",
      style: {
        color: "#323754",
      },
        profileLink: "https://www.hackerearth.com/@hannan_max",
    },
    {
      siteName: "Kaggle",
      iconifyClassname: "simple-icons:kaggle",
      style: {
        color: "#20BEFF",
      },
        profileLink: "https://www.kaggle.com/hannan_max",
    },
  ],
};

// Live Projects Page
const liveprojectsite = {
  liveprojectsite: [
    {
      siteName: "HomeworkHelper",
      image: "homeworkhelper.png",
      alt_name: "Homework Helper",
      style: {
        color: "#2EC866",
      },
      link: "https://hannanmax.github.io/HomeworkHelper/",
    },
    {
      siteName: "GhostBoard",
      image: "ghostboard.png",
      alt_name: "GhostBoard",
      style: {
        color: "#2EC866",
      },
      link: "https://play.google.com/store/apps/details?id=com.hannanmax.ghostboard",
    },
    {
      siteName: "StalkerIG",
      image: "stalkerig.png",
      alt_name: "StalkerIG",
      style: {
        color: "#2EC866",
      },
      link: "https://www.hannanmax.com/StalkerIG/",
    },
  ],
};

const websites = {
  websites: [
    {
      title: "Homework Helper",
      subtitle: "Website",
      logo_path: "homeworkhelper.png",
      alt_name: "Homework Helper",
      duration: "2021",
      descriptions: [
        "⚡ Homework Helper is a programme that converts text into a picture that appears like handwriting.",
        "⚡ Since I despise writing tasks, I created a programme that turns text into a picture that resembles handwriting. You may paste text into the textbox and then produce a picture by clicking the generate image button.",
        "⚡ You can download images individually or You can download all PDF too.",
      ],
      website_link: "https://hannanmax.github.io/HomeworkHelper/",
    },
    {
      title: "GhostBoard",
      subtitle: "Android Application",
      logo_path: "ghostboard.png",
      alt_name: "GhostBoard",
      duration: "2020",
      descriptions: [
        "⚡ This app let's you record the keyboard KeyStrokes",
        "⚡ NOTE: All logs are stored locally and no data is sent to any server.",
        "⚡ Log Location : Files > Internal Storage > Android > Data > com.hannanmax.GhostBoard > files > GhostBoard-files.txt",
      ],
      website_link: "https://play.google.com/store/apps/details?id=com.hannanmax.ghostboard",
    },
    {
      title: "StalkerIG",
      subtitle: "Website",
      logo_path: "stalkerig.png",
      alt_name: "StalkerIG",
      duration: "2021",
      descriptions: [
        "⚡ This site let's you see hidden mention tags in public users stories.",
        "⚡ Allows you to download HD profile picture, Story, Post, IGTV and Reels.",
        "⚡ StalkerIG allows you to create story to post.",
      ],
      website_link: "https://www.hannanmax.com/StalkerIG/",
    },
  ],
};

const degrees = {
  degrees: [
    {
      title: "Mobile Application Development - Post Graduate Diploma",
      subtitle: "CAMBRIAN COLLEGE",
      logo_path: "collegelogo.png",
      alt_name: "UTU",
      duration: "Sep 2022 - June 2023",
      descriptions: [
        "⚡ 1 Year, 3 semesters program. (3rd Co-Op)",
        "⚡ Programming Languages: Proficiency in languages like Java, Swift, Kotlin, or Flutter for app development on different platforms.",
        "⚡ UI/UX Design: Understanding user interface and experience principles to create visually appealing and user-friendly apps.",
        "⚡ API Integration: Learning how to integrate external APIs for fetching and displaying data in your apps.",
        "⚡ Deployment and Publishing: Navigating the process of deploying apps to app stores like Google Play Store and Apple App Store, including adhering to guidelines and preparing for submission.",
      ],
      website_link: "http://utu.ac.in",
    },
    {
      title: "Bachelors in computer science",
      subtitle: "UKA TARSADIA UNIVERSITY",
      logo_path: "utu.png",
      alt_name: "UTU",
      duration: "Aug 2016 - May 2019",
      descriptions: [
        "⚡ 3 Year, 6 semesters bachelor.",
        "⚡ I have studied basic software engineering subjects like DS, Algorithms, DBMS, OS, CA, AI etc.",
        "⚡ Apart from this, I have done courses on Deep Learning, Data Science, Cloud Computing and Full Stack Development.",
        "⚡ I was selected for Merit cum Means Scholarship which is given to top 10% of students in college. I have received award from respected director for consistently best performance in academics.",
      ],
      website_link: "http://utu.ac.in",
    },
  ],
};

const certifications = {
  certifications: [
    {
      title: "Machine Learning",
      subtitle: "- Andrew Ng",
      logo_path: "stanford_logo.png",
      certificate_link: "",
        /*"https://www.coursera.org/account/accomplishments/verify/22MTSSC5WDTM",*/
      alt_name: "Stanford University",
      color_code: "#8C151599",
    },
    {
      title: "Deep Learning",
      subtitle: "- Andrew Ng",
      logo_path: "deeplearning_ai_logo.png",
      certificate_link: "",
        /*"https://www.coursera.org/account/accomplishments/specialization/H8CPSFXAJD2G",*/
      alt_name: "deeplearning.ai",
      color_code: "#00000099",
    },
    {
      title: "ML on GCP",
      subtitle: "- GCP Training",
      logo_path: "google_logo.png",
      certificate_link: "",
        /*"https://www.coursera.org/account/accomplishments/specialization/EB4VJARK8647",*/
      alt_name: "Google",
      color_code: "#0C9D5899",
    },
    {
      title: "Data Science",
      subtitle: "- Alex Aklson",
      logo_path: "ibm_logo.png",
      certificate_link: "",
        /*"https://www.coursera.org/account/accomplishments/specialization/PLEAPCSJBZT5",*/
      alt_name: "IBM",
      color_code: "#1F70C199",
    },
    {
      title: "Big Data",
      subtitle: "- Kim Akers",
      logo_path: "microsoft_logo.png",
      certificate_link: "",
        /*"https://drive.google.com/file/d/164zKCFOsI4vGqokc-Qj-e_D00kLDHIrG/view",*/
      alt_name: "Microsoft",
      color_code: "#D83B0199",
    },
    {
      title: "Advanced Data Science",
      subtitle: "- Romeo Kienzler",
      logo_path: "ibm_logo.png",
      certificate_link: "",
        /*"https://www.coursera.org/account/accomplishments/verify/BH2T9BRU87BH",*/
      alt_name: "IBM",
      color_code: "#1F70C199",
    },
    {
      title: "Advanced ML on GCP",
      subtitle: "- GCP Training",
      logo_path: "google_logo.png",
      certificate_link: "",
        /*"https://www.coursera.org/account/accomplishments/verify/5JZZM7TNQ2AV",*/
      alt_name: "Google",
      color_code: "#0C9D5899",
    },
    {
      title: "DL on Tensorflow",
      subtitle: "- Laurence Moroney",
      logo_path: "deeplearning_ai_logo.png",
      certificate_link: "",
        /*"https://www.coursera.org/account/accomplishments/verify/6T4DCUGNK8J8",*/
      alt_name: "deeplearning.ai",
      color_code: "#00000099",
    },
    {
      title: "Fullstack Development",
      subtitle: "- Jogesh Muppala",
      logo_path: "coursera_logo.png",
      certificate_link: "",
        /*"https://www.coursera.org/account/accomplishments/certificate/NRANJA66Y2YA",*/
      alt_name: "Coursera",
      color_code: "#2A73CC",
    },
    {
      title: "Kuberenetes on GCP",
      subtitle: "- Qwiklabs",
      logo_path: "gcp_logo.png",
      certificate_link: "",
        /*"https://google.qwiklabs.com/public_profiles/e4d5a92b-faf6-4679-a70b-a9047c0cd750",*/
      alt_name: "GCP",
      color_code: "#4285F499",
    },
    {
      title: "Cryptography",
      subtitle: "- Saurabh Mukhopadhyay",
      logo_path: "nptel_logo.png",
      certificate_link: "",
        /*"https://drive.google.com/open?id=1z5ExD_QJVdU0slLkp8CBqSF3-C3g-ro_",*/
      alt_name: "NPTEL",
      color_code: "#FFBB0099",
    },
    {
      title: "Cloud Architecture",
      subtitle: "- Qwiklabs",
      logo_path: "gcp_logo.png",
      certificate_link: "",
        /*"https://google.qwiklabs.com/public_profiles/5fab4b2d-be6f-408c-8dcb-6d3b58ecb4a2",*/
      alt_name: "GCP",
      color_code: "#4285F499",
    },
  ],
};

// Experience Page
const experience = {
  title: "Experience",
  subtitle: "Work, Internship and Volunteership",
  description:
    "I have worked with many evolving startups as ML and DL Developer, Designer and Software Architect. I have also worked with some well established companies mostly as Mobile application Developer with IoT.",
  header_image_path: "experience.svg",
  sections: [
    {
      title: "Work",
      experiences: [
        {
          title: "President",
          company: "Cambrian Developer Club",
          company_url: "#",
          logo_path: "cdclogo.jpeg",
          duration: "Jan 2023 — PRESENT",
          location: "Sudbury, On, Canada",
          description:
            "• Provide leadership and guidance to the club, fostering a collaborative environment for members. • Organize and oversee various events, workshops, and Hackathons to promote skill development and innovation.",
          color: "#0879bf",
        },
        {
          title: "Research Assistant & Mobile Application Developer",
          company: "Cambrian Research & Development",
          company_url: "https://cambriancollege.ca/rd/",
          logo_path: "cambrianrandd.jpeg",
          duration: "Jan 2023 — PRESENT",
          location: "Sudbury, On, Canada",
          description:
            "• Contribute to the development of Android applications, ensuring high-quality code and functionality. • Collaborate with cross-functional teams to gather requirements and implement new features.",
          color: "#0879bf",
        },
        {
          title: "Immigration Counselor",
          company: "Kanan International Pvt. Ltd.",
          company_url: "https://www.kanan.co/bardoli/",
          logo_path: "kananinternational.png",
          duration: "Feb 2022 — Jun 2022",
          location: "Bardoli, India",
          description:
            "• Interacted with clients, providing them with information on the IELTS exam and assisting with visa inquiries. • Managed social media promotion for the branch and maintained Excel files for efficient record-keeping. • Prepared students for US visa interviews as an additional service.",
          color: "#0879bf",
        },
        {
          title: "Mobile Application Developer",
          company: "HMDevCoders Inc.",
          company_url: "https://www.linkedin.com/company/hmdevcoders/",
          logo_path: "hmdevcoders.png",
          duration: "Sep 2018 — Sep 2022",
          location: "Vyara, Gujarat",
          description:
            "• Develop mobile applications using programming languages and source code, both internally and for clients. • Successfully create well-received and commercially viable apps. • Collaborate effectively with team members, incorporating their input into projects. • Continuously enhance existing apps to increase user engagement and satisfaction. • Demonstrate strong attention to detail and persistence in delivering polished and exceptional products.",
          color: "#0879bf",
        },
        {
          title: "Chief Executive Officer at HMDevCoders Inc.",
          company: "HMDevCoders Inc.",
          company_url: "https://www.linkedin.com/company/hmdevcoders/",
          logo_path: "hmdevcoders.png",
          duration: "Jul 2016 — Sep 2022",
          location: "Vyara, India",
          description:
            "• Provide strategic direction and oversee all aspects of thecompany's operations. • Lead a team of professionals in delivering high-quality mobile app development services to clients. • Drive business growth and establish strong relationships with key stakeholders.",
          color: "#9b1578",
        },
      ],
    },
    {
      title: "Internships",
      experiences: [
        {
          title: "Research Assistant & Mobile Application Developer",
          company: "Cambrian Research & Development",
          company_url: "https://cambriancollege.ca/rd/",
          logo_path: "cambrianrandd.jpeg",
          duration: "May 2023 — Sep 2023",
          location: "Sudbury, On, Canada",
          description:
            "• Collaborated with a research team to develop research proposals and prepare grant applications • Developed a research database to store and organize data from the research project • Conducted a literature review to identify the latest research in a field of study, allowing for more informed decision-making on the research project",
          color: "#0879bf",
        },
        {
          title: "Data Entry and Management for indian Vehicle and licence",
          company: "Sardar Moter Driving Schooll Pvt. Ltd.",
          company_url: "https://goo.gl/maps/Mv8D9QdJqFLhN1Wf9",
          logo_path: "sardar.png",
          duration: "April 2019 - Sept 2020",
          location: "Bardoli, Gujarat, India",
          description:
            "I have worked on Data Entry for Indian Vehicle and licence systems. While being there i have also developed a better system for data entry and management. There were three objectives: (1) To build a better desktop application for data entry and management. (2) To manage the data entry of vehicles such as PUC, INSURANCE and VEHICLE REGISTRATION.",
          color: "#ee3c26",
        },
      ],
    },
    {
      title: "Volunteerships",
      experiences: [
        {
          title: "ConnectAplhabates",
          company: "Student (Payal Prajapti and Team)",
          company_url: "",
          logo_path: "connectalphabates.png",
          duration: "June 2019 - April 2020",
          location: "Bardoli, Gujarat, India",
          description:
            "I have votenteered my self to help the junior students to build an game call ConnectAplhapabates for their last year project at college.",
          color: "#4285F4",
        },
        {
          title: "Smart Parking System",
          company: "Student (Almira Shaikh and Team)",
          company_url: "https://www.linkedin.com/in/almira-shaikh-8379521a1",
          logo_path: "theparker.png",
          duration: "June 2019 - April 2020",
          location: "Bardoli, Gujarat, India",
          description:
            "I have votenteered my self to help the junior students to build an application called smart parking system for their last year project at college.",
          color: "#4285F4",
        },
        {
          title: "Developer Program Member",
          company: "Github",
          company_url: "https://github.com/",
          logo_path: "github_logo.png",
          duration: "July 2019 - PRESENT",
          location: "Work From Home",
          description:
            "I am actively contributing to many opensource projects. I have contributed to projects of organisations like Tensorflow, Uber, Facebook, Google, Scikit-learn, Kiwix, Sympy, Python, NVLabs, Fossasia, Netrack, Keras etc. These contributions include bug fixes, feature requests and formulating proper documentation for project.",
          color: "#181717",
        },
      ],
    },
  ],
};

// Projects Page
const projectsHeader = {
  title: "Projects",
  description:
    "My projects makes use of vast variety of latest technology tools. My best experience is to create Data Science projects and deploy them to web applications using cloud infrastructure.",
  avatar_image_path: "projects_image.svg",
};

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Contact Me",
    profile_image_path: "hannanmax.png",
    description:
      "I am available on almost every social media. You can message me, I will reply within 24 hours. I can help you with ML, AI, React, Android, Cloud and Opensource Development.",
  },
  addressSection: {
    title: "Address",
    subtitle:
      "1221 Notre Dame Ave #4, Greater Sudbury, ON P3A 2T8",
    avatar_image_path: "address_image.svg",
    location_map_link: "https://g.page/hannan_max?share",
  },
  phoneSection: {
    title: "Phone Number",
    subtitle: "+12896001062",
  },
  blogSection: {
    title: "Blogs",
    subtitle:
      "For individual fundamental empowerment, I like to write powerful lessons that create impact on each of the reader individually to change the core of their character.",
    link: "https://hannanmax.com",
    avatar_image_path: "blogs_image.svg",
  },
};

export {
  settings,
  seo,
  greeting,
  socialMediaLinks,
  skills,
  competitiveSites,
  liveprojectsite,
  websites,
  degrees,
  certifications,
  experience,
  projectsHeader,
  contactPageData,
};
