import React from "react";
import "./liveProjectsSites.css";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

class LiveProjectsSites extends React.Component {
  render() {
    return (
      <div className="competitive-sites-main-div">
        <ul className="dev-icons">
          {this.props.logos.map((logo) => {
            return (
              <a href={logo.link}>
              <img
                style={{
                  maxWidth: "15%",
                  maxHeight: "15%",
                  transform: "scale(50%, 50%)",
                }}
                src={require(`../../assests/images/${logo.image}`)}
                alt={logo.alt_name}
              />
              </a>
            );
          })}
        </ul>
      </div>
    );
  }
}

export default LiveProjectsSites;
