import React, { Component } from "react";

export default class GDGSudbury extends Component {
  render() {
    const theme = this.props.theme;
    return (
      <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" width="949" height="568.6528" viewBox="0 0 949 568.6528" >
      <path d="M493.95429,675.17362a31.51716,31.51716,0,0,1-30.43451-5.36117c-10.65985-8.94708-14.002-23.68733-16.72141-37.336l-8.04348-40.37019,16.83986,11.59529c12.11023,8.33865,24.49328,16.94444,32.87836,29.02256s12.04517,28.56585,5.308,41.63494" transform="translate(-125.5 -165.6736)" fill="#e6e6e6" />
      <path d="M492.51482,724.05014c1.70442-12.415,3.457-24.98956,2.26145-37.53768-1.06178-11.14409-4.46167-22.02873-11.38341-30.95339a51.49146,51.49146,0,0,0-13.2109-11.97129c-1.32109-.83387-2.537,1.25978-1.22166,2.09a48.95178,48.95178,0,0,1,19.363,23.36288c4.2156,10.72234,4.89256,22.41059,4.16611,33.8008-.43931,6.88809-1.37037,13.731-2.30861,20.5652a1.25406,1.25406,0,0,0,.84528,1.48875,1.21763,1.21763,0,0,0,1.48874-.84529Z" transform="translate(-125.5 -165.6736)" fill="#f2f2f2" />
      <path d="M476.09817,702.08138a23.20063,23.20063,0,0,1-20.2149,10.43338c-10.23314-.48578-18.7643-7.62786-26.444-14.40842l-22.7149-20.05553,15.0335-.71945c10.8112-.51738,21.90132-1,32.19861,2.334s19.794,11.35766,21.67693,22.01619" transform="translate(-125.5 -165.6736)" fill="#e6e6e6" />
      <path d="M497.33163,731.187c-8.20362-14.51579-17.71911-30.64843-34.72169-35.80468a38.74557,38.74557,0,0,0-14.60449-1.50794c-1.55056.1337-1.16337,2.52385.38438,2.39039a35.99579,35.99579,0,0,1,23.30647,6.166c6.57154,4.47307,11.68821,10.69183,16.0189,17.28653,2.65269,4.03945,5.02879,8.25028,7.40535,12.45545.7595,1.34389,2.97945.3738,2.21108-.98579Z" transform="translate(-125.5 -165.6736)" fill="#f2f2f2" />
      <path d="M717.21327,440.39728,538.36,588.63164a11.02654,11.02654,0,0,1-15.50846-1.45176L447.4565,496.21142a11.02655,11.02655,0,0,1,1.45176-15.50847L627.7615,332.46859A11.02656,11.02656,0,0,1,643.27,333.92035L718.665,424.88881A11.02655,11.02655,0,0,1,717.21327,440.39728Z" transform="translate(-125.5 -165.6736)" fill="#ccc" />
      <path d="M710.046,435.93377,535.30428,580.76044a5.68027,5.68027,0,0,1-7.98921-.74787l-71.98738-86.8569a5.68027,5.68027,0,0,1,.74788-7.98921L630.81725,340.33979a5.68026,5.68026,0,0,1,7.98921.74788l71.98737,86.85689A5.68026,5.68026,0,0,1,710.046,435.93377Z" transform="translate(-125.5 -165.6736)" fill="#fff" />
      <path d="M718.10135,466.25042l-35.94022-3.36416,25.72328-20.24515,13.857,16.71931a4.152,4.152,0,0,1,.48183,4.66117,4.27907,4.27907,0,0,1-1.05143,1.30238A4.15966,4.15966,0,0,1,718.10135,466.25042Z" transform="translate(-125.5 -165.6736)" fill="#ccc" />
      <path d="M645.92753,401.87527l-88.39874,73.26526a5.34016,5.34016,0,1,1-6.81537-8.22314l88.39873-73.26525a5.34016,5.34016,0,1,1,6.81538,8.22313Z" transform="translate(-125.5 -165.6736)" fill="#ccc" />
      <path d="M602.66388,408.25491l-59.61775,49.41145a5.34016,5.34016,0,1,1-6.81538-8.22313l59.61775-49.41146a5.34016,5.34016,0,0,1,6.81538,8.22314Z" transform="translate(-125.5 -165.6736)" fill="#ccc" />
      <circle cx="384.78656" cy="326.94689" r="16.688" fill="#6c63ff" />
      <circle cx="517.5324" cy="288.15569" r="5.34016" fill="#e6e6e6" />
      <circle cx="531.92289" cy="276.22879" r="5.34016" fill="#e6e6e6" />
      <circle cx="546.31338" cy="264.30189" r="5.34016" fill="#e6e6e6" />
      <polygon points="461.198 556.214 473.458 556.213 479.29 508.925 461.196 508.926 461.198 556.214" fill="#ffb8b8" />
      <path d="M583.57113,717.885l24.14379-.001h.001a15.38605,15.38605,0,0,1,15.38648,15.38623v.5l-39.53052.00147Z" transform="translate(-125.5 -165.6736)" fill="#2f2e41" />
      <polygon points="417.198 556.214 429.458 556.213 435.29 508.925 417.196 508.926 417.198 556.214" fill="#ffb8b8" />
      <path d="M539.57113,717.885l24.14379-.001h.001a15.38605,15.38605,0,0,1,15.38648,15.38623v.5l-39.53052.00147Z" transform="translate(-125.5 -165.6736)" fill="#2f2e41" />
      <path d="M555.001,707.41872h-.00219l-10.42554-.00293a4.48165,4.48165,0,0,1-4.49487-4.33789l-.32837-9.1211c-2.6355-72.94043-5.36084-148.36425,11.51733-182.26465l.13233-.2666,55.88012-2.02246,11.21851,102.91114a44.62081,44.62081,0,0,1-.52,13.16406L603.148,703.62673a4.49876,4.49876,0,0,1-4.59717,3.6582l-11.71045-.37988a4.50188,4.50188,0,0,1-4.30176-4.94336l9.199-72.2793a24.70205,24.70205,0,0,0-.83472-9.28418l-11.44043-39.99414a1.50016,1.50016,0,0,0-2.92822.20605L559.45755,703.53786A4.51837,4.51837,0,0,1,555.001,707.41872Z" transform="translate(-125.5 -165.6736)" fill="#2f2e41" />
      <circle cx="436.18347" cy="216.28068" r="24.56103" fill="#ffb8b8" />
      <path d="M604.66043,516.99977h-50.823a4.50035,4.50035,0,0,1-4.45313-5.15039l2.51075-17.19629-14.7439-40.55371a29.8437,29.8437,0,0,1,24.00879-39.7666h0a29.73019,29.73019,0,0,1,31.02075,16.81543l3.23853,6.89062L609.085,511.67946a4.49963,4.49963,0,0,1-4.42456,5.32031Z" transform="translate(-125.5 -165.6736)" fill="#6c63ff" />
      <path d="M497.37388,560.13581a10.74268,10.74268,0,0,0,7.44457-14.69442l39.60234-89.47207-22.65457-5.706L489.962,540.00569a10.80091,10.80091,0,0,0,7.41184,20.13012Z" transform="translate(-125.5 -165.6736)" fill="#ffb8b8" />
      <path d="M539.05977,471.97084l-22.03344-10.31357a4.81689,4.81689,0,0,1-1.97427-7.02164l13.39047-20.22269a13.37737,13.37737,0,0,1,24.198,11.41337l-6.92415,23.16293a4.81687,4.81687,0,0,1-6.65666,2.9816Z" transform="translate(-125.5 -165.6736)" fill="#6c63ff" />
      <path d="M662.5751,329.89742a10.7427,10.7427,0,0,1-8.80893,13.91942L597.1973,423.65142,580.69476,407.115l60.79449-73.275a10.80091,10.80091,0,0,1,21.08585-3.94258Z" transform="translate(-125.5 -165.6736)" fill="#ffb8b8" />
      <path d="M610.27876,414.28061a4.81164,4.81164,0,0,1-1.23486,3.81607l-16.34924,17.8092a13.37737,13.37737,0,0,1-22.19518-14.93937L581.01184,399.109a4.81688,4.81688,0,0,1,7.04207-1.90008l20.142,13.64334A4.81143,4.81143,0,0,1,610.27876,414.28061Z" transform="translate(-125.5 -165.6736)" fill="#6c63ff" />
      <path d="M593.95486,371.6736a25.25979,25.25979,0,0,0-.91846-6.57593c-3.957-14.62243-18.56543-23.39868-33.22509-21.19629a31.69966,31.69966,0,0,0-8.76856-1.22778h0a31.621,31.621,0,0,0-31.24609,26.767l-11.8418,76.233h64l10.334-66.52551a31.77725,31.77725,0,0,0,.269-7.47449Z" transform="translate(-125.5 -165.6736)" fill="#2f2e41" />
      <path d="M673.5,734.3264h-211a1,1,0,1,1,0-2h211a1,1,0,0,1,0,2Z" transform="translate(-125.5 -165.6736)" fill="#ccc" />
      <path d="M886.07028,243.34113a10.05579,10.05579,0,0,1,10.98633,10.81926l32.70724,14.395-15.60167,10.07083-28.66745-15.21368a10.11027,10.11027,0,0,1,.57555-20.07138Z" transform="translate(-125.5 -165.6736)" fill="#a0616a" />
      <circle cx="892.50184" cy="35.14965" r="24.56103" fill="#a0616a" />
      <polygon points="848.05 385.364 835.79 385.363 829.958 338.075 848.052 338.076 848.05 385.364" fill="#a0616a" />
      <path d="M976.67645,562.92134l-39.53052-.00147v-.5a15.38605,15.38605,0,0,1,15.38647-15.38623h.001l24.1438.001Z" transform="translate(-125.5 -165.6736)" fill="#2f2e41" />
      <polygon points="899.05 385.364 886.79 385.363 880.958 338.075 899.052 338.076 899.05 385.364" fill="#a0616a" />
      <path d="M1027.67645,562.92134l-39.53052-.00147v-.5a15.38605,15.38605,0,0,1,15.38647-15.38623h.001l24.1438.001Z" transform="translate(-125.5 -165.6736)" fill="#2f2e41" />
      <path d="M1023.96878,540.96559a4.50658,4.50658,0,0,1-.54883-.03418l-17.9458-2.209a4.49289,4.49289,0,0,1-3.94434-4.23535L996.30032,416.7234a1.49993,1.49993,0,0,0-2.85742-.55371l-13.43433,44.78418L977.242,532.8904a4.498,4.498,0,0,1-5.0227,4.29492l-15.54053-1.8291a4.48512,4.48512,0,0,1-3.97315-4.55664l1.31934-67.71387,10.52881-106.292.47046.01758,64.02221,2.46777-.5769,177.20117a4.50146,4.50146,0,0,1-4.50073,4.48535Z" transform="translate(-125.5 -165.6736)" fill="#2f2e41" />
      <path d="M1026.62014,368.992c-.03906,0-.07812,0-.11743-.001l-64.479-1.68945a4.49979,4.49979,0,0,1-4.34107-5.10449l12.39014-91.19141a44.13237,44.13237,0,0,1,18.68018-30.792,38.19591,38.19591,0,0,1,31.10815-5.54688c22.5061,5.70313,36.00757,29.87012,30.09717,53.87207l-18.968,77.0293A4.52648,4.52648,0,0,1,1026.62014,368.992Z" transform="translate(-125.5 -165.6736)" fill="#3f3d56" />
      <path d="M942.02981,296.5779a21.82211,21.82211,0,0,1-11.96411-3.55469l-37.60229-24.66992a4.4992,4.4992,0,0,1-.79883-6.85645l5.66455-5.98047a4.48379,4.48379,0,0,1,4.93164-1.08691l42.00171,16.72559,38.90869-27.78614a16.15455,16.15455,0,1,1,16.44629,27.67774l-48.08814,23.35742A21.74021,21.74021,0,0,1,942.02981,296.5779Z" transform="translate(-125.5 -165.6736)" fill="#3f3d56" />
      <path d="M1026.51241,224.31132c4.19022.9894,9.24531,1.74863,12.23986-1.34484a12.5105,12.5105,0,0,0,1.41592-1.89006l3.9152-5.97741c2.9992-4.57894,6.05107-9.27733,7.3205-14.60185a23.74125,23.74125,0,0,0-17.90687-28.67359c-5.84568-1.30882-11.56585-3.56078-17.54216-3.15s-12.98468.27592-18-3c.31289,1.46046,2.00253,7.9134,2.00253,7.9134s-7.5414,6.91867-9.054,8.64384a14.1663,14.1663,0,0,1,19.723,10.67121c.4281,2.62585.17666,5.538,1.73668,7.69318,1.48376,2.0498,4.14514,2.768,6.38173,3.95152a15.56319,15.56319,0,0,1,6.84685,19.04477Z" transform="translate(-125.5 -165.6736)" fill="#2f2e41" />
      <path d="M997.02308,377.78843H793.43361a9.66388,9.66388,0,0,1-9.653-9.653V264.58566a9.66388,9.66388,0,0,1,9.653-9.653H997.02308a9.66387,9.66387,0,0,1,9.65295,9.653V368.13548A9.66387,9.66387,0,0,1,997.02308,377.78843Z" transform="translate(-125.5 -165.6736)" fill="#ccc" />
      <path d="M994.683,370.7681H795.77372a4.97829,4.97829,0,0,1-4.97273-4.97273v-98.8696a4.97829,4.97829,0,0,1,4.97273-4.97273H994.683a4.9783,4.9783,0,0,1,4.97274,4.97273v98.8696A4.9783,4.9783,0,0,1,994.683,370.7681Z" transform="translate(-125.5 -165.6736)" fill="#fff" />
      <path d="M983.16361,395.73038l-22.37044-22.37015,28.68.725V393.1169a3.63886,3.63886,0,0,1-2.28169,3.41475,3.75017,3.75017,0,0,1-1.43786.2908A3.64556,3.64556,0,0,1,983.16361,395.73038Z" transform="translate(-125.5 -165.6736)" fill="#ccc" />
      <path d="M970.46457,311.92684H869.83988a4.68022,4.68022,0,1,1,0-9.36044H970.46457a4.68022,4.68022,0,0,1,0,9.36044Z" transform="translate(-125.5 -165.6736)" fill="#ccc" />
      <path d="M937.703,292.03591H869.83988a4.68022,4.68022,0,1,1,0-9.36043H937.703a4.68021,4.68021,0,1,1,0,9.36043Z" transform="translate(-125.5 -165.6736)" fill="#ccc" />
      <circle cx="702.68566" cy="131.62756" r="14.62568" fill="#6c63ff" />
      <circle cx="813.95497" cy="179.69184" r="4.68022" fill="#e6e6e6" />
      <circle cx="830.33573" cy="179.69184" r="4.68022" fill="#e6e6e6" />
      <circle cx="846.71649" cy="179.69184" r="4.68022" fill="#e6e6e6" />
      <path d="M993.8706,365.474a10.05578,10.05578,0,0,1,12.8385-8.53983l20.78344-29.06936,6.665,17.33237-20.75813,24.94752a10.11027,10.11027,0,0,1-19.52885-4.6707Z" transform="translate(-125.5 -165.6736)" fill="#a0616a" />
      <path d="M1017.67789,363.9055a4.53555,4.53555,0,0,1-.98107-.02466,4.48884,4.48884,0,0,1-3.20506-2.06l-4.39223-6.96833a4.47981,4.47981,0,0,1,.16818-5.04824l26.602-36.55347-17.29062-44.57587a16.06991,16.06991,0,0,1,8.81348-20.73946h0a16.154,16.154,0,0,1,22.07333,11.65569l10.72775,52.37339a21.8839,21.8839,0,0,1-6.65051,20.45445l-33.21,30.32536A4.4868,4.4868,0,0,1,1017.67789,363.9055Z" transform="translate(-125.5 -165.6736)" fill="#3f3d56" />
      <path d="M1073.5,565.3264h-211a1,1,0,0,1,0-2h211a1,1,0,0,1,0,2Z" transform="translate(-125.5 -165.6736)" fill="#ccc" />
      <path d="M382.11757,316.44985H178.5281a9.66388,9.66388,0,0,1-9.653-9.653V203.24708a9.66387,9.66387,0,0,1,9.653-9.653H382.11757a9.66387,9.66387,0,0,1,9.65295,9.653V306.7969A9.66388,9.66388,0,0,1,382.11757,316.44985Z" transform="translate(-125.5 -165.6736)" fill="#ccc" />
      <path d="M379.77746,309.42952H180.86821a4.97829,4.97829,0,0,1-4.97273-4.97273v-98.8696a4.9783,4.9783,0,0,1,4.97273-4.97274H379.77746a4.9783,4.9783,0,0,1,4.97273,4.97274v98.8696A4.97829,4.97829,0,0,1,379.77746,309.42952Z" transform="translate(-125.5 -165.6736)" fill="#fff" />
      <path d="M368.2581,334.3918l-22.37044-22.37015,28.68.725v19.03167a3.63886,3.63886,0,0,1-2.28169,3.41475,3.7502,3.7502,0,0,1-1.43786.2908A3.64558,3.64558,0,0,1,368.2581,334.3918Z" transform="translate(-125.5 -165.6736)" fill="#ccc" />
      <path d="M355.55905,250.58826H254.93437a4.68022,4.68022,0,1,1,0-9.36044H355.55905a4.68022,4.68022,0,0,1,0,9.36044Z" transform="translate(-125.5 -165.6736)" fill="#ccc" />
      <path d="M322.79753,230.69733H254.93437a4.68022,4.68022,0,1,1,0-9.36044h67.86316a4.68022,4.68022,0,1,1,0,9.36044Z" transform="translate(-125.5 -165.6736)" fill="#ccc" />
      <circle cx="87.78015" cy="70.28897" r="14.62568" fill="#6c63ff" />
      <circle cx="199.04946" cy="118.35326" r="4.68022" fill="#e6e6e6" />
      <circle cx="215.43022" cy="118.35326" r="4.68022" fill="#e6e6e6" />
      <circle cx="231.81098" cy="118.35326" r="4.68022" fill="#e6e6e6" />
      <path d="M373.6744,183.645a10.05575,10.05575,0,0,0-5.31,14.47619l-23.4203,26.99016,18.40886,2.43876,19.418-26.0042A10.11027,10.11027,0,0,0,373.6744,183.645Z" transform="translate(-125.5 -165.6736)" fill="#ffb8b8" />
      <path d="M217.29809,194.45055a10.0558,10.0558,0,0,1-7.75445,13.32757l-5.82806,35.25639L190.08807,230.42l7.62367-31.54613a10.11027,10.11027,0,0,1,19.58635-4.42336Z" transform="translate(-125.5 -165.6736)" fill="#ffb8b8" />
      <polygon points="128.399 385.364 140.659 385.363 146.491 338.075 128.397 338.076 128.399 385.364" fill="#ffb8b8" />
      <path d="M250.77164,547.03462l24.1438-.001h.001a15.38605,15.38605,0,0,1,15.38647,15.38623v.5l-39.53052.00147Z" transform="translate(-125.5 -165.6736)" fill="#2f2e41" />
      <polygon points="65.399 385.364 77.659 385.363 83.491 338.075 65.397 338.076 65.399 385.364" fill="#ffb8b8" />
      <path d="M187.77164,547.03462l24.1438-.001h.001a15.38605,15.38605,0,0,1,15.38647,15.38623v.5l-39.53052.00147Z" transform="translate(-125.5 -165.6736)" fill="#2f2e41" />
      <circle cx="125.83969" cy="37.94709" r="24.56103" fill="#ffb8b8" />
      <path d="M248.39557,535.32816l-2.03076-129.40625a1.49985,1.49985,0,0,0-2.95923-.32422l-33.291,126.57617a4.55506,4.55506,0,0,1-4.571,3.459l-18.29-.78223a4.50093,4.50093,0,0,1-4.272-5.06055l28.24878-162.6582.05786-.459,78.68213,3.72265.51318.02344L273.61115,535.59867a4.51892,4.51892,0,0,1-4.48731,4.166H252.89508A4.51825,4.51825,0,0,1,248.39557,535.32816Z" transform="translate(-125.5 -165.6736)" fill="#2f2e41" />
      <path d="M310.54034,381.35746l-114.17773-9.37012a4.49926,4.49926,0,0,1-3.86353-6.01465l17.98072-49.71191,1.03467-59.00293a16.18147,16.18147,0,0,1,16.667-15.89063l7.593-6.88281h27.81958l2.42236,3.17578,19.35718,2.97461a16.27225,16.27225,0,0,1,13.58007,13.833L315.368,376.27249a4.5006,4.5006,0,0,1-4.45386,5.10059C310.7901,381.37308,310.66534,381.3682,310.54034,381.35746Z" transform="translate(-125.5 -165.6736)" fill="#2f2e41" />
      <path d="M288.29425,246.35453l48.68359-14.26368L359.01056,203.083a4.49872,4.49872,0,0,1,5.35229-1.416l13.75635,5.87988a4.49956,4.49956,0,0,1,2.11914,6.40332l-16.46557,28.25586A25.19721,25.19721,0,0,1,352.119,252.62894l-57.26123,25.3125Z" transform="translate(-125.5 -165.6736)" fill="#2f2e41" />
      <path d="M194.89288,267.69144a20.86486,20.86486,0,0,1-8.60376-18.5752l2.13648-28.30761a4.51169,4.51169,0,0,1,4.48755-4.16114h.03979l16.13184.14356a4.5009,4.5009,0,0,1,4.376,5.36523l-2.99316,15.27149,21.36011,9.02539-17.98706,34.99316Z" transform="translate(-125.5 -165.6736)" fill="#2f2e41" />
      <path d="M265.62782,174.1697s-25.685-13.83022-39.685,2.49239,5.3339,41.8476,5.3339,41.8476l27.548,3.4744s1-18.72648,7.559-18.36293S293.31286,180.35469,265.62782,174.1697Z" transform="translate(-125.5 -165.6736)" fill="#2f2e41" />
      <path d="M337.5,564.3264h-211a1,1,0,0,1,0-2h211a1,1,0,0,1,0,2Z" transform="translate(-125.5 -165.6736)" fill="#ccc" />
    </svg>
    );
  }
}
