import React from "react";
import "./LoaderLogo.css";

class LogoLoader extends React.Component {
  render() {
    const theme = this.props.theme;
    return (
      <svg 
        width="512" 
        height="512" 
        viewBox="0 0 516 681" 
        fill="black" 
        xmlns="http://www.w3.org/2000/svg">

        <mask id="path-1-outside-1" maskUnits="userSpaceOnUse" x="0.544922" y="3.05176e-05" width="516" height="681" fill="white">
          <rect fill="white" x="0.544922" y="3.05176e-05" width="516" height="681"/>
          <path class="brain" d="M129.62 70.057L3.30659 138.824L3 279.839V420.855L43.4695 442.907C65.8503 454.803 101.414 474.243 122.569 485.56C143.723 497.166 162.732 507.321 164.878 507.902C167.944 509.062 168.557 503.839 167.944 484.109L167.024 458.575L127.168 437.104C105.093 425.207 79.3401 411.57 69.5293 406.637L52.0539 397.642L52.6671 283.031L53.5868 168.13L70.4491 159.135C79.6467 154.202 125.022 129.249 171.316 104.295C246.123 63.3834 255.934 58.741 262.372 61.6425L269.731 64.8342V264.751C269.731 374.72 270.65 465.539 272.183 466.409C273.41 466.99 285.06 461.477 297.936 454.223L321.544 440.585L323.383 267.073L336.873 299.57C344.231 317.269 351.283 331.777 352.202 331.777C353.429 331.777 361.707 316.979 370.904 298.99L387.766 266.492L388.686 335.549C388.993 374.14 390.526 404.316 392.059 403.736C393.592 403.155 404.629 397.642 416.586 391.259L438.353 379.653L437.434 281.29L436.82 183.218L413.826 170.741C401.256 163.777 389.913 159.135 388.993 160.005C387.766 161.166 381.635 176.254 375.503 193.953C362.626 228.482 355.881 244.731 355.575 240.668C355.575 239.218 347.91 210.202 338.713 176.544C322.463 116.772 321.85 113.29 321.85 75.8601L321.544 37.2695L289.965 18.9897C272.49 9.12438 257.774 1.00003 257.16 1.00003C256.547 1.00003 199.216 32.0467 129.62 70.057Z"/>
          <path class="brain" d="M346.377 79.0518V105.456L358.334 112.42C365.079 116.482 391.139 130.99 416.586 144.917L462.88 169.87V394.161L362.626 448.71C273.716 497.456 261.759 503.259 255.321 500.358L248.269 497.166V296.959C248.269 128.088 247.656 96.7513 243.977 96.7513C241.525 96.7513 229.874 101.394 217.917 106.907L196.15 117.062V259.238H128.701V208.171C128.701 178.865 127.474 157.684 125.941 158.264C124.408 158.554 113.371 164.358 101.414 170.741L79.6467 182.347L80.5665 280.71L81.1797 379.073L124.102 404.026L125.022 354.699L125.635 305.663H196.15V528.793L226.502 544.751C243.057 553.746 258.08 561 259.613 561C262.066 561 480.049 442.326 508.255 425.788L515 421.725V141.145L435.901 98.7824C392.365 75.5699 354.348 55.5492 351.589 54.3886C346.99 52.9378 346.377 55.2591 346.377 79.0518Z"/>
          <path class="h" d="M46.3203 679H36.8184V627.594H11.0469V679H1.54492V572.018H11.0469V619.049H36.8184V572.018H46.3203V679Z"/>
          <path class="a" d="M99.9824 679H91.0273V677.154C87.4271 677.656 83.8268 678.134 80.2266 678.59C75.7604 679.137 72.1829 679.41 69.4941 679.41C64.5267 679.41 62.043 676.949 62.043 672.027V647.76C62.043 642.656 64.6178 640.103 69.7676 640.103H91.1641V620.074H71.6133V631.422H62.7949V620.211C62.7949 615.061 65.347 612.486 70.4512 612.486H92.3262C97.4303 612.486 99.9824 615.061 99.9824 620.211V679ZM91.1641 670.728V647.213H70.8613V671.139L91.1641 670.728Z"/>
          <path class="n" d="M153.098 679H144.006V620.621L123.977 621.168V679H114.885V612.486H123.977V614.332C127.531 613.876 131.109 613.398 134.709 612.896C139.084 612.35 142.684 612.076 145.51 612.076C150.568 612.076 153.098 614.56 153.098 619.527V679Z"/>
          <path class="n" d="M206.008 679H196.916V620.621L176.887 621.168V679H167.795V612.486H176.887V614.332C180.441 613.876 184.019 613.398 187.619 612.896C191.994 612.35 195.594 612.076 198.42 612.076C203.479 612.076 206.008 614.56 206.008 619.527V679Z"/>
          <path class="a" d="M258.303 679H249.348V677.154C245.747 677.656 242.147 678.134 238.547 678.59C234.081 679.137 230.503 679.41 227.814 679.41C222.847 679.41 220.363 676.949 220.363 672.027V647.76C220.363 642.656 222.938 640.103 228.088 640.103H249.484V620.074H229.934V631.422H221.115V620.211C221.115 615.061 223.667 612.486 228.771 612.486H250.646C255.751 612.486 258.303 615.061 258.303 620.211V679ZM249.484 670.728V647.213H229.182V671.139L249.484 670.728Z"/>
          <path class="n" d="M311.418 679H302.326V620.621L282.297 621.168V679H273.205V612.486H282.297V614.332C285.852 613.876 289.429 613.398 293.029 612.896C297.404 612.35 301.005 612.076 303.83 612.076C308.889 612.076 311.418 614.56 311.418 619.527V679Z"/>
          <path class="m" d="M407.6 679H398.645V611.256C398.645 610.299 398.964 607.496 399.602 602.848L382.102 668.951H380.188L362.688 602.848C363.326 607.542 363.645 610.344 363.645 611.256V679H354.689V572.018H363.508L380.871 641.607C380.962 641.972 381.053 642.997 381.145 644.684C381.145 644 381.236 642.975 381.418 641.607L398.781 572.018H407.6V679Z"/>
          <path class="a" d="M461.193 679H452.238V677.154C448.638 677.656 445.038 678.134 441.438 678.59C436.971 679.137 433.394 679.41 430.705 679.41C425.738 679.41 423.254 676.949 423.254 672.027V647.76C423.254 642.656 425.829 640.103 430.979 640.103H452.375V620.074H432.824V631.422H424.006V620.211C424.006 615.061 426.558 612.486 431.662 612.486H453.537C458.641 612.486 461.193 615.061 461.193 620.211V679ZM452.375 670.728V647.213H432.072V671.139L452.375 670.728Z"/>
          <path class="m" d="M512.189 679H502.961L491.75 652.682L480.402 679H471.447V678.727L486.965 644.82L472.404 612.486H481.633L491.887 636.891L502.414 612.486H511.232V612.76L496.672 644.547L512.189 679Z"/>
        </mask>

        <path class="brain" d="M129.62 70.057L3.30659 138.824L3 279.839V420.855L43.4695 442.907C65.8503 454.803 101.414 474.243 122.569 485.56C143.723 497.166 162.732 507.321 164.878 507.902C167.944 509.062 168.557 503.839 167.944 484.109L167.024 458.575L127.168 437.104C105.093 425.207 79.3401 411.57 69.5293 406.637L52.0539 397.642L52.6671 283.031L53.5868 168.13L70.4491 159.135C79.6467 154.202 125.022 129.249 171.316 104.295C246.123 63.3834 255.934 58.741 262.372 61.6425L269.731 64.8342V264.751C269.731 374.72 270.65 465.539 272.183 466.409C273.41 466.99 285.06 461.477 297.936 454.223L321.544 440.585L323.383 267.073L336.873 299.57C344.231 317.269 351.283 331.777 352.202 331.777C353.429 331.777 361.707 316.979 370.904 298.99L387.766 266.492L388.686 335.549C388.993 374.14 390.526 404.316 392.059 403.736C393.592 403.155 404.629 397.642 416.586 391.259L438.353 379.653L437.434 281.29L436.82 183.218L413.826 170.741C401.256 163.777 389.913 159.135 388.993 160.005C387.766 161.166 381.635 176.254 375.503 193.953C362.626 228.482 355.881 244.731 355.575 240.668C355.575 239.218 347.91 210.202 338.713 176.544C322.463 116.772 321.85 113.29 321.85 75.8601L321.544 37.2695L289.965 18.9897C272.49 9.12438 257.774 1.00003 257.16 1.00003C256.547 1.00003 199.216 32.0467 129.62 70.057Z" fill="white"/>
        <path class="brain" d="M346.377 79.0518V105.456L358.334 112.42C365.079 116.482 391.139 130.99 416.586 144.917L462.88 169.87V394.161L362.626 448.71C273.716 497.456 261.759 503.259 255.321 500.358L248.269 497.166V296.959C248.269 128.088 247.656 96.7513 243.977 96.7513C241.525 96.7513 229.874 101.394 217.917 106.907L196.15 117.062V259.238H128.701V208.171C128.701 178.865 127.474 157.684 125.941 158.264C124.408 158.554 113.371 164.358 101.414 170.741L79.6467 182.347L80.5665 280.71L81.1797 379.073L124.102 404.026L125.022 354.699L125.635 305.663H196.15V528.793L226.502 544.751C243.057 553.746 258.08 561 259.613 561C262.066 561 480.049 442.326 508.255 425.788L515 421.725V141.145L435.901 98.7824C392.365 75.5699 354.348 55.5492 351.589 54.3886C346.99 52.9378 346.377 55.2591 346.377 79.0518Z" fill="white"/>
        <path class="h" d="M46.3203 679H36.8184V627.594H11.0469V679H1.54492V572.018H11.0469V619.049H36.8184V572.018H46.3203V679Z" fill="white"/>
        <path class="a" d="M99.9824 679H91.0273V677.154C87.4271 677.656 83.8268 678.134 80.2266 678.59C75.7604 679.137 72.1829 679.41 69.4941 679.41C64.5267 679.41 62.043 676.949 62.043 672.027V647.76C62.043 642.656 64.6178 640.103 69.7676 640.103H91.1641V620.074H71.6133V631.422H62.7949V620.211C62.7949 615.061 65.347 612.486 70.4512 612.486H92.3262C97.4303 612.486 99.9824 615.061 99.9824 620.211V679ZM91.1641 670.728V647.213H70.8613V671.139L91.1641 670.728Z" fill="white"/>
        <path class="n" d="M153.098 679H144.006V620.621L123.977 621.168V679H114.885V612.486H123.977V614.332C127.531 613.876 131.109 613.398 134.709 612.896C139.084 612.35 142.684 612.076 145.51 612.076C150.568 612.076 153.098 614.56 153.098 619.527V679Z" fill="white"/>
        <path class="n" d="M206.008 679H196.916V620.621L176.887 621.168V679H167.795V612.486H176.887V614.332C180.441 613.876 184.019 613.398 187.619 612.896C191.994 612.35 195.594 612.076 198.42 612.076C203.479 612.076 206.008 614.56 206.008 619.527V679Z" fill="white"/>
        <path class="a" d="M258.303 679H249.348V677.154C245.747 677.656 242.147 678.134 238.547 678.59C234.081 679.137 230.503 679.41 227.814 679.41C222.847 679.41 220.363 676.949 220.363 672.027V647.76C220.363 642.656 222.938 640.103 228.088 640.103H249.484V620.074H229.934V631.422H221.115V620.211C221.115 615.061 223.667 612.486 228.771 612.486H250.646C255.751 612.486 258.303 615.061 258.303 620.211V679ZM249.484 670.728V647.213H229.182V671.139L249.484 670.728Z" fill="white"/>
        <path class="n" d="M311.418 679H302.326V620.621L282.297 621.168V679H273.205V612.486H282.297V614.332C285.852 613.876 289.429 613.398 293.029 612.896C297.404 612.35 301.005 612.076 303.83 612.076C308.889 612.076 311.418 614.56 311.418 619.527V679Z" fill="white"/>
        <path class="m" d="M407.6 679H398.645V611.256C398.645 610.299 398.964 607.496 399.602 602.848L382.102 668.951H380.188L362.688 602.848C363.326 607.542 363.645 610.344 363.645 611.256V679H354.689V572.018H363.508L380.871 641.607C380.962 641.972 381.053 642.997 381.145 644.684C381.145 644 381.236 642.975 381.418 641.607L398.781 572.018H407.6V679Z" fill="white"/>
        <path class="a" d="M461.193 679H452.238V677.154C448.638 677.656 445.038 678.134 441.438 678.59C436.971 679.137 433.394 679.41 430.705 679.41C425.738 679.41 423.254 676.949 423.254 672.027V647.76C423.254 642.656 425.829 640.103 430.979 640.103H452.375V620.074H432.824V631.422H424.006V620.211C424.006 615.061 426.558 612.486 431.662 612.486H453.537C458.641 612.486 461.193 615.061 461.193 620.211V679ZM452.375 670.728V647.213H432.072V671.139L452.375 670.728Z" fill="white"/>
        <path class="x" d="M512.189 679H502.961L491.75 652.682L480.402 679H471.447V678.727L486.965 644.82L472.404 612.486H481.633L491.887 636.891L502.414 612.486H511.232V612.76L496.672 644.547L512.189 679Z" fill="white"/>
        
        <path class="brain" d="M129.62 70.057L3.30659 138.824L3 279.839V420.855L43.4695 442.907C65.8503 454.803 101.414 474.243 122.569 485.56C143.723 497.166 162.732 507.321 164.878 507.902C167.944 509.062 168.557 503.839 167.944 484.109L167.024 458.575L127.168 437.104C105.093 425.207 79.3401 411.57 69.5293 406.637L52.0539 397.642L52.6671 283.031L53.5868 168.13L70.4491 159.135C79.6467 154.202 125.022 129.249 171.316 104.295C246.123 63.3834 255.934 58.741 262.372 61.6425L269.731 64.8342V264.751C269.731 374.72 270.65 465.539 272.183 466.409C273.41 466.99 285.06 461.477 297.936 454.223L321.544 440.585L323.383 267.073L336.873 299.57C344.231 317.269 351.283 331.777 352.202 331.777C353.429 331.777 361.707 316.979 370.904 298.99L387.766 266.492L388.686 335.549C388.993 374.14 390.526 404.316 392.059 403.736C393.592 403.155 404.629 397.642 416.586 391.259L438.353 379.653L437.434 281.29L436.82 183.218L413.826 170.741C401.256 163.777 389.913 159.135 388.993 160.005C387.766 161.166 381.635 176.254 375.503 193.953C362.626 228.482 355.881 244.731 355.575 240.668C355.575 239.218 347.91 210.202 338.713 176.544C322.463 116.772 321.85 113.29 321.85 75.8601L321.544 37.2695L289.965 18.9897C272.49 9.12438 257.774 1.00003 257.16 1.00003C256.547 1.00003 199.216 32.0467 129.62 70.057Z" stroke="white" stroke-width="2" mask="url(#path-1-outside-1)"/>
        <path class="brain" d="M346.377 79.0518V105.456L358.334 112.42C365.079 116.482 391.139 130.99 416.586 144.917L462.88 169.87V394.161L362.626 448.71C273.716 497.456 261.759 503.259 255.321 500.358L248.269 497.166V296.959C248.269 128.088 247.656 96.7513 243.977 96.7513C241.525 96.7513 229.874 101.394 217.917 106.907L196.15 117.062V259.238H128.701V208.171C128.701 178.865 127.474 157.684 125.941 158.264C124.408 158.554 113.371 164.358 101.414 170.741L79.6467 182.347L80.5665 280.71L81.1797 379.073L124.102 404.026L125.022 354.699L125.635 305.663H196.15V528.793L226.502 544.751C243.057 553.746 258.08 561 259.613 561C262.066 561 480.049 442.326 508.255 425.788L515 421.725V141.145L435.901 98.7824C392.365 75.5699 354.348 55.5492 351.589 54.3886C346.99 52.9378 346.377 55.2591 346.377 79.0518Z" stroke="white" stroke-width="2" mask="url(#path-1-outside-1)"/>
        <path class="h" d="M46.3203 679H36.8184V627.594H11.0469V679H1.54492V572.018H11.0469V619.049H36.8184V572.018H46.3203V679Z" stroke="white" stroke-width="2" mask="url(#path-1-outside-1)"/>
        <path class="a" d="M99.9824 679H91.0273V677.154C87.4271 677.656 83.8268 678.134 80.2266 678.59C75.7604 679.137 72.1829 679.41 69.4941 679.41C64.5267 679.41 62.043 676.949 62.043 672.027V647.76C62.043 642.656 64.6178 640.103 69.7676 640.103H91.1641V620.074H71.6133V631.422H62.7949V620.211C62.7949 615.061 65.347 612.486 70.4512 612.486H92.3262C97.4303 612.486 99.9824 615.061 99.9824 620.211V679ZM91.1641 670.728V647.213H70.8613V671.139L91.1641 670.728Z" stroke="white" stroke-width="2" mask="url(#path-1-outside-1)"/>
        <path class="n" d="M153.098 679H144.006V620.621L123.977 621.168V679H114.885V612.486H123.977V614.332C127.531 613.876 131.109 613.398 134.709 612.896C139.084 612.35 142.684 612.076 145.51 612.076C150.568 612.076 153.098 614.56 153.098 619.527V679Z" stroke="white" stroke-width="2" mask="url(#path-1-outside-1)"/>
        <path class="n" d="M206.008 679H196.916V620.621L176.887 621.168V679H167.795V612.486H176.887V614.332C180.441 613.876 184.019 613.398 187.619 612.896C191.994 612.35 195.594 612.076 198.42 612.076C203.479 612.076 206.008 614.56 206.008 619.527V679Z" stroke="white" stroke-width="2" mask="url(#path-1-outside-1)"/>
        <path class="a" d="M258.303 679H249.348V677.154C245.747 677.656 242.147 678.134 238.547 678.59C234.081 679.137 230.503 679.41 227.814 679.41C222.847 679.41 220.363 676.949 220.363 672.027V647.76C220.363 642.656 222.938 640.103 228.088 640.103H249.484V620.074H229.934V631.422H221.115V620.211C221.115 615.061 223.667 612.486 228.771 612.486H250.646C255.751 612.486 258.303 615.061 258.303 620.211V679ZM249.484 670.728V647.213H229.182V671.139L249.484 670.728Z" stroke="white" stroke-width="2" mask="url(#path-1-outside-1)"/>
        <path class="n" d="M311.418 679H302.326V620.621L282.297 621.168V679H273.205V612.486H282.297V614.332C285.852 613.876 289.429 613.398 293.029 612.896C297.404 612.35 301.005 612.076 303.83 612.076C308.889 612.076 311.418 614.56 311.418 619.527V679Z" stroke="white" stroke-width="2" mask="url(#path-1-outside-1)"/>
        <path class="m" d="M407.6 679H398.645V611.256C398.645 610.299 398.964 607.496 399.602 602.848L382.102 668.951H380.188L362.688 602.848C363.326 607.542 363.645 610.344 363.645 611.256V679H354.689V572.018H363.508L380.871 641.607C380.962 641.972 381.053 642.997 381.145 644.684C381.145 644 381.236 642.975 381.418 641.607L398.781 572.018H407.6V679Z" stroke="white" stroke-width="2" mask="url(#path-1-outside-1)"/>
        <path class="a" d="M461.193 679H452.238V677.154C448.638 677.656 445.038 678.134 441.438 678.59C436.971 679.137 433.394 679.41 430.705 679.41C425.738 679.41 423.254 676.949 423.254 672.027V647.76C423.254 642.656 425.829 640.103 430.979 640.103H452.375V620.074H432.824V631.422H424.006V620.211C424.006 615.061 426.558 612.486 431.662 612.486H453.537C458.641 612.486 461.193 615.061 461.193 620.211V679ZM452.375 670.728V647.213H432.072V671.139L452.375 670.728Z" stroke="white" stroke-width="2" mask="url(#path-1-outside-1)"/>
        <path class="x" d="M512.189 679H502.961L491.75 652.682L480.402 679H471.447V678.727L486.965 644.82L472.404 612.486H481.633L491.887 636.891L502.414 612.486H511.232V612.76L496.672 644.547L512.189 679Z" stroke="white" stroke-width="2" mask="url(#path-1-outside-1)"/>
        <defs>
        <style>
          
        </style>
      </defs>
      </svg>
    );
  }
}

export default LogoLoader;
